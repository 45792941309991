// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { Link } from 'gatsby'

import { RenderModules } from 'src/utils/renderModules'
import { Image } from 'src/components/image'

import { Location, LocationClinic, LocationVirtual } from 'src/components/svgs'
import { SEO } from 'src/components/seo'
import { ButtonBlock } from 'src/components/inline/buttonBlock'
import Content from 'src/components/content'

export interface AppointmentProps {
  pageContext: {
    main: {
      modules: []
      slug: {
        current: string
      }
      appointmentLink: {
        _type: string
        content: { main: { slug: { current: string } } }
      }
      title: string
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Appointment = ({ path, pageContext }: AppointmentProps) => {
  const {
    main: { title, shortDescription, modules, image, appointmentType, slug },
    meta,
    env,
  } = pageContext

  const handleType = (type: string) => {
    switch (type) {
      case 'in-clinic-only':
        return 'In-Clinic Only'
      case 'virtual-only':
        return 'Virtual Only'
      case 'in-clinic-and-virtual':
        return 'In-Clinic & Virtual'
    }
  }

  const handleIcon = (type: string) => {
    switch (type) {
      case 'in-clinic-only':
        return <LocationClinic className="mr05 appointment__icon" />
      case 'virtual-only':
        return <LocationVirtual className="mr05 appointment__icon" />
      case 'in-clinic-and-virtual':
        return <Location className="mr05 appointment__icon" />
    }
  }

  const url = path
  return (
    <div className="bg-secondary-2">
      <SEO metaInfo={meta} pagePath={path} env={env} />
      <div className="pym p1">
        <div className="container--l f jcb aic fw mxa outer p1 pym">
          <Image className="x col-m-4" cls="br20" imageId={image.asset._id} />
          <div className="x col-m-6">
            <div
              className="mb1 ss15 f jcs aic px1 py05 br5 caps mono cw bg-primary-3"
              style={{ width: 'fit-content', marginBottom: '-15px' }}
            >
              {handleType(appointmentType)}
            </div>
            <h1 className="h2">{title}</h1>
            <p className="ss16 sm21">{shortDescription}</p>
            <div style={{ marginTop: '30px' }}>
              {pageContext.main.appointmentLink && (
                <ButtonBlock data={pageContext.main.appointmentLink} />
              )}
            </div>
            <a
              href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
              className="block mt1 pt1 ss14 underline tertiary-3"
            >
              Already a Tia member? Book here
            </a>
          </div>
        </div>
      </div>
      <Content modules={modules}></Content>
    </div>
  )
}

export default Appointment
